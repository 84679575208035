@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

.counter-main {
    width: 100%;
    height:200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter-area {
    width: 460px;
    height:180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0px 40px;
}

.counter-day {
    width: 130px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Clicker Script", cursive;
    font-size: 60px;
    font-weight: 600;
    font-style: normal;
    color: #a1a4c4;
    color: #182062;
    background-color: #a1a4c4;
    border-radius: 10px;
}

.counter-text{
    font-family: "Architects Daughter", cursive;
    font-size: 30px;
}

@media(max-width:620px){
    .counter-main {
        height: 100px;
    }

    .counter-day {
        width: 80px;
        height: 80px;
        font-size: 30px;
    }

    .counter-text {
        font-size: 17px;
    }
}
