
@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');

.title-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.conf-title {
    font-family: "Clicker Script", cursive;
    font-size: 60px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

.espaco-presenca {
    width: 100%;
    height: 20px;
}

.conf-instrução{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon-presenca {
    width: 40px;
    height: 40px;
    color: #182062;
}

.conf-text, .icasei-text {
    font-family: "Architects Daughter", cursive;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    text-align: center;
}

.icasei-img-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.icasei-text {
    font-size: 15px;
}

#myIframe-CP {
    width: 100%;
    height: 600px;
}

#logoThais-img {
    width: 80px;
    height: 80px;
    margin-top: 20px;
}

@media(max-width:440px){
    .conf-title {
        font-size: 40px;
    }

    .conf-text {
        width: 90%;
        font-size: 16px;
        text-align: center;
    }
    .icasei-text {
        font-size: 13px;
    }
}