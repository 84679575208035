@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');

#banner {
    width: 100%;
    height: auto;
}

.home-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Italianno", cursive;
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

.data-text {
    font-size: 50px;
}

#banner2 {
    display: none;
}

@media(max-width:440px){
    #banner2 {
        display: block;
        width: 100%;
        height: auto;
    }

    #banner {
        display: none;
    }

    .home-main {
        font-size: 30px;
    }
}