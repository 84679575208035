#banner {
    width: 100%;
    height: auto;
}

#banner2 {
    display: none;
}

@media(max-width:440px){
    #banner2 {
        display: block;
        width: 100%;
        height: auto;
    }

    #banner {
        display: none;
    }
}