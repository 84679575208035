@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

p {
    display: flex;
    justify-content: center;
    font-family: "Italianno", cursive;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

#festa-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
}

#festa-map {
    width:100%;
    height: 400px;
    border: 0;
}

.festa-img-map {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.festa-img, .festa-map {
    width:40%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:left;
    color: #182062;
}

#info-extra {
    font-family: "Caveat", cursive;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    display: flex;
    justify-content: space-around;
    color: #182062;
    margin-bottom: 20px;
}

.festa-mobile{
    display: none;
}

@media(max-width:620px){
    .festa-mobile{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .festa-mobile-text {
        width: 90%;
    }

    .festa {
        display: none;
    }

    .festa-img-map {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 900px;
    }

    .festa-img, .festa-map {
        width:90%;
        height: 250px;
    }
}