@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

footer {
    border-color:#dcd7ca;
    border-top-width: 2px;
    border-top-style: solid;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

#logo {
    width: 80px;
    height: 80px;
    margin-top: 20px;
}

#text-footer {
    font-family: "Dancing Script", cursive;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    color: #182062;
}

.footer-rights {
    margin-bottom: 20px;
}

.nav-footer {
    display: none;
}

@media(max-width:440px){
    #text-footer {
        margin-bottom: 0px; 
    }

    .nav-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items: center;
    }

    .footer-list {
        width: 80%;
        display:flex;
        flex-direction: column;
        align-items: left;
    }

    li {
        width: 85%;
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}