
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.title-div {
 display: flex;
 justify-content: center;
 padding: 15px;
 text-align: center;
}

.title {
    font-family: "Great Vibes", serif;
    font-size: 60px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    text-align: center;
}