@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Sofia&display=swap');

.MenuPart{
    display:flex;
    flex-direction: column;
    align-items:center;
}

.MenuPart-title {
    font-family: "Great Vibes", serif;
    font-size: 35px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    text-align: center;
}

.MenuPart-title-2 {
    font-family: "Great Vibes", serif;
    font-size: 25px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    text-align: center;
}

.MenuPart-body {
    font-family: "Architects Daughter", serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    text-align: center;
}