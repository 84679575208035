@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

.main-mensagens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #182062;
}

.msg-title {
    font-family: "Clicker Script", cursive;
    font-size: 60px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    text-align: center;
}

.msg-text, .icasei-text {
    font-family: "Architects Daughter", cursive;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    text-align: center;
}

.icasei-img-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.icasei-text {
    font-size: 15px;
}

#myIframe-msg {
    width: 100%;
    height: 1200px;
}

@media(max-width:440px){
    .msg-title {
        font-size: 40px;
        width: 80%;
        text-align: center;
    }

    .msg-text {
        width: 90%;
        font-size: 16px;
        text-align: center;
    }
    .icasei-text {
        font-size: 13px;
    }
}