@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

.presentes-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    font-family: "Italianno", cursive;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

.espaco-extra {
    width: 100%;
    height: 20px;
}

.presente-title {
    font-size: 40px;
}

.espaco-presenca {
    width: 100%;
    height: 20px;
}

.opcao {
    width: 80%;
    display: flex;
    flex-direction:column;
}

.op-title {
    font-family: "Clicker Script", cursive;
    font-size: 35px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

.op-text {
    font-family: "Architects Daughter", cursive;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

.pix-email {
    text-align: center;
}

.pix-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.pix-info-text {
    width: 305px;
    height: 150px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pix-info-img {
    width: 30%;
    align-items: center;
    justify-content: center;
}

.pix-qrcode-img {
    width: 20%;
    text-align: center;
    align-items: center;
    justify-content: space-around;
}

#nubank-img {
    width: 200px;
    border-radius: 10px;
}

#qrcode-img {
    width: 180px;
}

#pix-email {
    font-family: "Dancing Script", cursive;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

#pix-extra {
    font-family: "Architects Daughter", cursive;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    text-align: center;
}

.espaco {
    width: 100%;
    height: 70px;
}

.icasei-img-main {
    width: 100%;
    display: flex;
    justify-content: center;
}

.icasei-img-link {
    width: 130px;
}

#icasei-img {
    width: 100%;
    padding-top: 10px;
}

@media(max-width:620px){
    .presente-title {
        width: 90%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
    }

    .opcao {
        text-align: justify;
    }

    .op-title {
        font-size: 30px;
    }

    .op-text {
        font-size: 17px;
    }

    .pix-info {
        flex-direction: column;
    }

    .pix-info-text {
        width: 95%;
        height: auto;
    }

    .pix-info-img, .pix-qrcode-img {
        width: 95%;
        display: flex ;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;    
    }
}