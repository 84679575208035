@import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap');

.espaco-extra {
    width: 100%;
    height: 20px;
}

.title-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-text {
    font-family: "Clicker Script", cursive;
    font-size: 60px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

@media(max-width:440px){
    .title-text {
        font-size: 40px;
    }
}