@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');

.nav-header, .nav-header-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul {
    display: flex;
    width: 1000px;
    justify-content: space-between;
}

li {
    list-style: none;
    font-family: "Italianno", cursive;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    cursor: pointer;
}

.icon {
    color: #182062;
    width: 25px;
    height: 25px;
}

.nav-header-mobile {
    display: none;
}


@media(max-width:1000px){
    .nav-header {
        display: none;
    }
    
    .icons-header-main {
        display: flex;
        width: 90%;
        justify-content: space-around;
    }

    .nav-header-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    .espaco {
        height:10px ;
    }
}
