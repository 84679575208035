@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');

p {
    display: flex;
    justify-content: center;
    font-family: "Italianno", cursive;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
}

#igreja-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
}

#igreja-map {
    width:100%;
    height: 400px;
    border: 0;
}

.igreja-img-map {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
}

.igreja-img, .igreja-map {
    width:40%;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:left;
}

.igreja-text-mobile{
    display: none;
}

@media(max-width:620px){
    .igreja-img-map {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 900px;
    }

    .igreja-img, .igreja-map {
        width:90%;
        height: 250px;
    }

    .igreja-text-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .igreja-mobile {
        width: 90%;
    }

    .igreja {
        display: none;
    }
}