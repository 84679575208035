
.title-I {
    font-family: "Great Vibes", serif;
    font-size: 60px;
    font-weight: 400;
    font-style: normal;
    color: #182062;
    text-align: center;
}

@media(max-width:400px){
    .title-I {
        font-size: 55px;
    }
}

.info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}